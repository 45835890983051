<template>

  <!-- {{user}} -->
  <div class="col-lg-10 col-md-10 ">
    <div class="card">
      <!-- 媒體庫功能區塊 -->
      <!-- <div class="card-body p-0">
        <div class="user-tabing p-3"> -->
      <!-- <div class="d-flex justify-content-between align-items-center"> -->
      <!-- <div class="d-flex m-1"> -->
      <!-- <button type="button" class="btn-primary btn rounded-pill me-1">
                  {{ $t('label.all') }}
                </button> -->
      <!-- <router-link :to="{ name: 'social.profileimage' }">
                <button type="button" class="btn-primary btn rounded-pill me-1">
                  {{ $t('label.photo') }}
                </button>
              </router-link> -->
      <!-- <router-link :to="{ name: 'social.profilevideo' }">
                <button type="button" class="btn-outline-primary btn rounded-pill me-1">
                  {{ $t('label.video') }}
                </button>
              </router-link> -->
      <!-- </div> -->
      <!-- <div class="d-flex align-items-center">
                <tooltip tag="a" type="" href="/media-list" className="me-3 iq-notify bg-soft-primary rounded"
                  tooltipPlacement="top" data-bs-original-title="類別"><i class="ri-folder-line"></i></tooltip>
                <div class="dropdown">
                  <tooltip tag="span" type="" className="dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false" tooltipPlacement="top" :data-bs-original-title="$t('label.displayMore')"><i
                      class="ri-more-fill h4 "></i></tooltip>
                  <div class="dropdown-menu dropdown-menu-right" style="">
                    <a class="dropdown-item" href="#"><i
                        class="ri-thumb-up-line me-2"></i>{{$t('label.mostLikes')}}</a><a class="dropdown-item"
                      href="#"><i class="ri-money-dollar-circle-line me-2"></i>{{$t('label.maximumRewardAmount')}}</a><a
                      class="dropdown-item" href="#"><i
                        class="ri-sort-asc me-2"></i>{{$t('label.ascendingOrder')}}</a><a class="dropdown-item"
                      href="#"><i class="ri-sort-desc me-2"></i>{{$t('label.descendingOrder')}}</a>
                  </div>
                </div>
              </div> -->
      <!-- </div> -->
      <!-- </div>
      </div> -->
      <!---->
    </div>
    <div v-if="profileImages.length >=1" class="row images" v-infinite-scroll="getRows" :infinite-scroll-disabled="commomData.noResult" v-viewer="viewOptions" infinite-scroll-delay="1000" :infinite-scroll-distance="50">

      <div class="col-4 mb-2 col-lg-3 col-md-4" v-for="(item, index) in profileImages" :key="index">
        <div class="user-images position-relative overflow-hidden">
          <a @click="show()" href="javascript:void(0)">
            <img :src="item.media_square_url" :data-source="item.media_url" class="img-fluid rounded" />
          </a>
        </div>
      </div>
    </div>
    <div v-else class="card card-block card-stretch card-height">
      <div class="card-body text-center">{{ $t('label.noInformation') }}</div>
    </div>
    <!-- <div v-if="commomData.listLoader" class="d-block text-center mb-0 pb-0">
      <img src="@/assets/images/page-img/page-load-loader.gif" alt="loader" style="height: 100px" />
    </div> -->
  </div>

</template>
<script>
// import { scroll } from '@/config/pluginScroll'
import { getMedia } from '@/api/media'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
import { directive as viewer } from 'v-viewer'

export default {
  name: 'ProfileImage',
  mounted() {
    this.$nextTick(() => {
      // scroll.onscroll()
    })
  },
  created() {
    this.getRows()
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState'
    })
  },
  directives: {
    viewer: viewer({
      debug: true
    })
  },
  data() {
    return {
      viewOptions: {
        toolbar: true,
        url: 'data-source'
      },
      commomData: commomData(),
      primaryKey: 'media_category_id',
      profileImages: [],
      iconClass: ['ri-thumb-up-line', 'ri-chat-3-line'],
      isFetching: false // 新增變量來追蹤是否正在請求
    }
  },
  methods: {
    show() {
      const viewer = this.$el.querySelector('.images').$viewer
      viewer.show()
    },
    async getRows() {
      if (this.commomData.noResult || this.isFetching) {
        return false
      }
      this.commomData.listLoader = true
      this.commomData.condition.order_by = 'created_at'
      this.commomData.condition.media_category_id = -1
      this.commomData.condition.type = 'photo'
      this.isFetching = true // 鎖定請求
      await getMedia(this.commomData.condition)
        .then(res => {
          if (this.commomData.condition.page === 1) {
            this.profileImages = res.data
            this.commomData.condition.page = 2
            this.commomData.listLoader = false
            if (res.total === 0) {
              this.commomData.noResult = true
            }
          } else if (res.total > this.profileImages.length) {
            this.profileImages.push(...res.data)
            this.commomData.condition.page = this.commomData.condition.page + 1
            this.commomData.listLoader = false
          } else {
            this.commomData.noResult = true
            this.commomData.listLoader = false
          }
        })
        .catch(err => {
          console.log(err)
          this.commomData.btnLoader = false
          this.$swal.mixin().fire({
            icon: 'error',
            title: err.response.data.message
          })
        })
        .finally(() => {
          this.isFetching = false // 請求完成後解鎖
        })
    }
  }
}
</script>
<style scoped >
.user-images {
  width: 100%;
  height: auto;
  max-width: 300px; /* PC版預設最大寬度 */
  overflow: hidden;
  position: relative;
}
/* 設定圖片的大小和顯示方式 */
.user-images img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持圖片的比例，不變形，填滿容器 */
  object-position: center; /* 確保圖片在容器中居中 */
}
/* 手機版樣式：最大寬度為 767px */
@media (max-width: 767px) {
  .user-images {
    width: 100%;
    height: 200px; /* 手機版固定高度 */
  }
}

/* 平板版樣式：768px - 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .user-images {
    width: 100%;
    height: 300px; /* 平板版固定高度 */
  }
}

/* 桌面版樣式：大於 1024px */
@media (min-width: 1025px) {
  .user-images {
    width: 270px; /* 桌面版固定寬度 */
    height: 270px; /* 桌面版固定高度 */
  }
}
</style>